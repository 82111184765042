<template>
  <div
    class="SearchResult  relative desk:layout-container
           pt-18 type-sm desk:flex items-start"
    :data-insights-index="globalContent.getAlgoliaIndex"
  >
    <div class="basis-2/12 shrink-0 desk:mr-12">
      <div v-if="searchRunOnce && !products.length && totalOtherPages === 0" class="type-sm-medium mobOnly:px-12 mb-24">
        {{ $t('search.nohits') }}
      </div>

      <div v-else class="mobOnly:px-12 mobOnly:pb-12">
        <div
          v-if="suggestions.length || brandSuggestions.length || totalOtherPages === 0"
          class="type-headline-xs desk:type-headline-sm mb-4"
        >
          {{ $t('search.suggestions') }}
        </div>

        <!-- admin suggestion -->
        <div v-if="!suggestions.length && !brandSuggestions.length && totalOtherPages === 0">
          <div v-for="(adminSuggestion, index) in globalContent.getSearchSuggestions" :key="index">
            <button
              class="type-headline-lg desk:type-headline-xl my-2 hover:text-darker transition-all"
              @click="$emit('select', adminSuggestion)"
            >
              {{ adminSuggestion }}
            </button>
          </div>
        </div>

        <!-- suggested brandpages -->
        <nuxt-link
          v-for="brandSuggestion in brandSuggestions"
          :key="brandSuggestion.brandCode"
          :to="'/' + brandSuggestion.brandLink"
          class="flex justify-between pb-16 items-center"
        >
          <div v-if="brandSuggestion.brandLogo" class="basis-32 mr-8 shrink-0 flex items-center justify-center">
            <img
              :src="brandSuggestion.brandLogo"
              class="max-w-32 max-h-32"
              alt="logo"
            >
          </div>
          <div class="basis-full" v-html="autoCompleteName(brandSuggestion.brandName)" />
          <div class="type-headline-xs text-dark pl-4 whitespace-nowrap">
            {{ $t('search.brandSuggestion') }}
          </div>
        </nuxt-link>

        <!-- suggested words from search -->
        <div
          v-for="suggestion in suggestions"
          :key="suggestion.objectID"
        >
          <button
            v-if="search.toLowerCase() !== suggestion.query.toLowerCase()"
            class="type-sm pb-16 text-left"
            @click="$emit('select', suggestion.query)"
            v-html="autoCompleteName(suggestion.query)"
          />
        </div>

        <!-- show all -->
        <div
          v-if="suggestions.length < totalProducts && totalProducts > -1"
          class="flex justify-between items-center mt-12"
        >
          <div class="type-headline-xs desk:hidden">
            {{ totalProducts === 1 ? $t('search.page.hits.sing') : $t('search.page.hits.plur', {num:totalProducts}) }}
          </div>
          <button
            class="mobOnly:hidden btn--text"
            @click="$emit('goToSearchPage')"
          >
            {{ $t('search.showAll') }}
          </button>
          <button
            class="btn--text text-12 desk:hidden"
            @click="$emit('goToSearchPage')"
          >
            {{ $t('search.showAll') }}
          </button>
        </div>
      </div>
    </div>
    <!-- search result -->
    <div class="basis-10/12">
      <div
        v-if="totalProducts > 0"
        class="mobOnly:hidden type-headline-sm mb-8"
      >
        {{ totalProducts === 1 ? $t('search.page.hits.sing') : $t('search.page.hits.plur', {num:totalProducts}) }}
      </div>
      <div
        v-if="products.length > 0"
        class="grid grid-cols-2 tabletPortraitOnly:grid-cols-3 desk:grid-cols-4 gap-3 desk:gap-12"
      >
        <ProductCard
          v-for="(product, productIndex) in products"
          :key="product.partNo"
          :product="product"
          :enable-quick-buy="true"
          design="standard"
          :placement="1"
          class="algoliaClickTrigger"
          :data-insights-object-id="product.objectID"
          :data-insights-position="productIndex + 1"
          :data-insights-query-id="queryID"
        />
      </div>

      <div
        v-else-if="popularProducts.length && totalOtherPages === 0"
      >
        <div class="type-headline-sm mb-8 mobOnly:px-12">{{ $t('search.popularProducts') }}</div>

        <div class="grid grid-cols-2 tabletPortraitOnly:grid-cols-3 desk:grid-cols-4 gap-3 desk:gap-12">
          <ProductCard
            v-for="product in popularProducts"
            :key="product.partNo"
            :product="product"
            :enable-quick-buy="true"
            design="standard"
            :placement="1"
          />
        </div>
      </div>

      <!-- search pages -->
      <SearchResultPages
        :search="search"
        :in-modal="true"
        :class="{
          'border-t border-light pt-12': products.length
        }"
        @total-hits="(e) => totalOtherPages = e"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ProductCard from '~/components/product/ProductCard.vue';
import { useUiStore } from '~/store/ui';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import {  AlgoliaSearchSuggestions, ProductRaw } from '~/constants/types/algolia';
import { BrandItem, useGlobalContentStore } from '~/store/globalContent';
import SearchResultPages from '~/components/search/SearchResultPages.vue';

const globalContent = useGlobalContentStore();

const uiStore = useUiStore();
const { freeTextSearch, getPopularProducts, convertRawToProducts } = useAlgoliaFetch();

defineEmits<{
  (e: 'select', input: string): void;
  (e: 'goToSearchPage'): void;
}>();

const props = defineProps<{
  search: string,
}>();

onMounted(()=> {
  loadPopularProducts();
  globalContent.loadBrands();
});

const products = ref<ProductRaw[]>([]);
const hitsPerPage = 12;
const totalProducts = ref(-1);
const totalOtherPages = ref(0);
const searchRunOnce = ref(false);
const timer = ref<any>(null);

watch(() => props.search, ()=> {
  clearTimeout(timer.value);
  if (props.search.length > 1) {
    timer.value = setTimeout(() => {
      doSearch();
    }, 500);
  }
});

const queryID = ref('');

const doSearch = async() => {
  if (props.search.length > 0) {
    getSuggestions();
    uiStore.searchIsLoading = true;
    const res = await freeTextSearch<ProductRaw[]>(
      props.search,
      hitsPerPage,
      '_search'
    );

    if (res) {
      products.value = convertRawToProducts(
        res?.hits ?? [],
        [],
        [],
        true
      ).map((m) => m.product);
      totalProducts.value = res.nbHits;
      queryID.value = res.queryID || '';
    }
    uiStore.searchIsLoading = false;
    searchRunOnce.value = true;
  }
};

const popularProducts = ref<ProductRaw[]>([]);

const loadPopularProducts = async() => {
  const res = await getPopularProducts<ProductRaw[]>(12);
  if (res) {
    popularProducts.value = convertRawToProducts(res?.hits ?? []).map((m) => m.product);
  } else {
    console.error('Quiet fail: get popular products');
  }
};

const autocomplete = ref<AlgoliaSearchSuggestions[]>([]);
const getSuggestions = async() => {
  const filter = {
    query: props.search,
  };
  const { data, error } = await useAsyncAlgoliaSearch({
    query: '',
    indexName: globalContent.getAlgoliaIndexAutocomplete,
    requestOptions: filter,
    key: JSON.stringify(filter),
  });

  if (data?.value) {
    autocomplete.value = data.value.hits;
  } else {
    // This should fail quietly
    console.warn('Error getting algolia search suggestions');
    console.log(error);
  }
};

const suggestions = computed(()=> {
  return autocomplete.value.filter((f)=> f.query.toLowerCase() !== props.search.toLowerCase());
});

const autoCompleteName = (input: string) => {
  const regex = new RegExp(props.search, 'gi');
  input = input.charAt(0).toUpperCase() + input.slice(1);
  input = input.replace(regex, '<u>$&</u>');
  return input;
};

const brandSuggestions = computed(()=> {
  if (props.search.length < 3) {
    return [] as BrandItem[];
  }
  const startsWith = globalContent.brandList.filter((f)=> f.brandName.toLowerCase().startsWith(props.search.toLowerCase()));
  const contains = globalContent.brandList.filter((f)=> {
    if (startsWith.findIndex((fi) => fi.brandCode === f.brandCode) > -1) {
      return false;
    }
    return f.brandName.toLowerCase().includes(props.search.toLowerCase());
  });
  return startsWith.concat(contains);
});

onBeforeUnmount(()=> {
  document.body.classList.remove('noScroll');
});

watch(()=> uiStore.showDesktopSearch, (newVal) => {
  if (newVal) {
    document.body.classList.add('noScroll');
  } else {
    document.body.classList.remove('noScroll');
  }
});

</script>

<style scoped lang="postcss">
</style>
