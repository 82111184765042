<template>
  <component
    :is="GenericSideMenu"
    position="right"
    class="EditProductDiscountModal"
    :title="$t('checkout.orderProposal.editDiscount')"
    @close="uiStore.closeAllModal()"
  >
    <MiniCartProduct
      v-for="item in items"
      :key="item.partNo"
      :item="item"
      :hide-delete="true"
      :hide-multi-discount="true"
    />
    <div class="type-sm-medium my-24 flex gap-8">
      <div class="basis-full">
        <TextInput
          v-model="discountPercentage"
          :type="'number'"
          :label="$t('priceInfo.discountLabel') + ' %'"
          :attr="{min: '0', max: '100'}"
        />
      </div>

      <div class="basis-full">
        <TextInput
          v-model="selectDiscountQuantity"
          :type="'number'"
          :label="$t('orders.table.quanity')"
        />
      </div>
      <button
        class="btn btn--lg"
        :class="{ 'loading': loading }"
        :disabled="loading"
        @click="applyDiscount"
      >
        {{ $t('minicart.discount.activate') }}
      </button>
    </div>
    <button
      :class="{ 'disabled': loading }"
      class="btn w-full btn mb-24"
      @click="uiStore.closeAllModal()"
    >
      {{ $t('search.cancel') }}
    </button>
  </component>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import useApiFetch from '~/composeables/useApiFetch';
import MiniCartProduct from '~/components/cart/MiniCartProduct.vue';
import TextInput from '~/components/form-elements/TextInput.vue';

const uiStore = useUiStore();
const cartStore = useCartStore();

const { handleApiError } = useApiFetch();
const discountPercentage = ref<number>(0);
const items = ref([]);
const selectDiscountQuantity = ref(0);
const loading = ref(false);

onMounted(()=> {
  setItem();
});

const setItem = () => {
  items.value = [uiStore.currentlyEditingItem];
};

const applyDiscount = async() => {
  loading.value = true;
  let res = null;
  const discount = discountPercentage.value;

  if (selectDiscountQuantity.value > 0 && uiStore.currentlyEditingItem) {
    res = await cartStore.updateProductDiscount(Number(uiStore.currentlyEditingItem.lineNo), discount.toString(), selectDiscountQuantity.value);
    if (res) {
      cartStore.cart = res;
      uiStore.currentlyEditingItem = res.items?.filter(item => item.lineNo === uiStore.currentlyEditingItem.lineNo)[0];
    } else {
      handleApiError();
    }
  }

  loading.value = false;
};

watch(() => uiStore.currentlyEditingItem, () => {
  setItem();
});
</script>

<style scoped lang="postcss">
</style>
