<template>
  <GenericSideMenu
    position="right"
    class="ImpersonateModal"
    content-class="!p-0"
    :title="`${$t('impersonateModal.title')}`"
    @close="close"
  >
    <div class="px-16 py-24 desk:px-24">
      <div>
        <TextInput
          v-model="searchInput"
          class="w-full"
          type="text"
          :is-search="true"
          :label="$t('mypage.customer.searchPlaceholder')"
        />
      </div>
      <div class="pt-24">
        <div
          v-for="customer in searchedCustomers"
          :key="customer.id"
          class="flex mb-24 cursor-pointer relative"
          @click="impersonate(customer)"
        >
          <div
            v-if="!customer.invoicePaymentMethodAvailable"
            class="h-32 w-32 top-[-8px] left-[-12px] flex items-center justify-center bg-criticalDark rounded-full absolute border-lightest border-2"
          >
            <img src="/mypage/invoice-fail-inv.svg" class="w-16 h-16">
          </div>
          <div class="rounded-full bg-brandPrimary w-40 h-40 mr-12 flex justify-center items-center">
            <img src="/icons/smiley.svg" class="h-24 w-24">
          </div>
          <div>
            <div class="type-sm-medium">
              {{ customer.name }}
            </div>
            <div class="type-xs-medium text-dark">
              {{ customer.code }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </GenericSideMenu>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';

import { useUiStore } from '~/store/ui';

import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useUserStore } from '~/store/user';
import TextInput from '~/components/form-elements/TextInput.vue';
import { Company } from '~/constants/types/norce';
import { onMounted } from 'vue';
import useApiFetch from '~/composeables/useApiFetch';

const userStore = useUserStore();
const uiStore = useUiStore();

const { apiGet } = useApiFetch();
const { salesUrl } = useLinkReplacer();

const { $t } = useNuxtApp();

const searchInput = ref('');
const customers = ref<Company[]>([]);

onMounted(() => {
  getUserCustomers();
});

const getUserCustomers = async() => {
  const res = await apiGet<Company[]>('/users/salesrep/companies');
  if (res) {
    customers.value = res;
  }
};

const searchedCustomers = computed(() => {
  const regex = new RegExp(searchInput.value, 'i');
  return customers.value.filter(customer => regex.test(customer.name) || (customer.orgNo && regex.test(customer.orgNo)) ||(customer.address?.city && regex.test(customer.address.city) || (customer.code && regex.test(customer.code))));
});

const impersonate = async(company: Company) => {
  const res = await userStore.impersonateCompany(company.id);
  if (res) {
    if (!company.invoicePaymentMethodAvailable) {
      uiStore.setTemporarySuccessMsg($t('checkout.invoice.notAvailableForCompany', { companyName: company.name }), 'error', 5000);
    }

    navigateTo(salesUrl('dashboard'));
    close();
  }
};

const close = () => {
  uiStore.setImpersonateModal(false);
};

</script>

<style scoped lang="postcss">
</style>
