<template>
  <div
    class="TempMsg flex justify-center fixed bottom-0 desk:bottom-24 z-globalError w-full cursor-pointer p-12"
    @click="uiStore.tempMsg = ''"
  >
    <div
      class="successmsg px-12 py-12 bg-mintPrimary type-sm flex items-start gap-6 mobOnly:w-full"
      :class="{
        'color-mintPrimary': uiStore.tempMsgType === 'success',
        'color-criticalDark text-white text-12 py-8': uiStore.tempMsgType === 'error',
      }"
    >
      <div class="p-2 shrink-0">
        <img
          src="/icons/check-circle.svg"
          class="w-20 h-20"
          alt=""
        >
      </div>
      <div class="shrink grow mt-1"> {{ uiStore.tempMsg }} </div>
      <div class="p-2 shrink-0 rounded-6 hover:bg-transparentBlack transition-all">
        <img
          src="/icons/close.svg"
          class="w-20 h-20"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();

const closeIcon = computed((): string => {
  return uiStore.tempMsgType === 'error'
    ? '/icons/close-inv.svg'
    : '/icons/close.svg';
});

</script>

<style scoped lang="postcss">

</style>
