<template>
  <div class="px-12 pt-4 desk:pt-16 desk:px-24 flex justify-between w-full items-center my-8">
    <div class="relative w-full">
      <TextInput
        v-model="loadId"
        :label="$t('loadCart.placeholder')"
        class="w-full"
        :errors="[ $t('loadCart.validation')]"
        :show-error="!valid"
        @enter="loadCart"
      />
      <div class="absolute top-4 right-4">
        <button
          class="btn"
          :disabled="loadId === ''"
          :class="{
            loading
          }"
          @click="loadCart"
        >
          {{ $t('loadCart.submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from '~/components/form-elements/TextInput.vue';
import useApiFetch from '~/composeables/useApiFetch';
import { useCartStore } from '~/store/cart';
import { useUiStore } from '~/store/ui';
const cartStore = useCartStore();

const uiStore = useUiStore();

const { apiGet, handleApiError } = useApiFetch();

const loadId = ref('');
const loading = ref(false);

const loadCart = async() => {
  if (loadId.value.trim() !== '') {
    loading.value = true;
    const res = await apiGet<string>(`/basket/norce/${loadId.value}`);

    if (res) {
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' });
      cartId.value = res;

      setTimeout(async()=> {
        await cartStore.getCart(false);
        uiStore.closeAllModal();
      });
      loading.value = false;
    } else {
      handleApiError();
      loading.value = false;
    }
  }
};

const valid = computed(()=> {
  if (loadId.value.trim() === '') {
    return true;
  }
  const regex = new RegExp('^[0-9]+$');
  return regex.test(loadId.value);
});

</script>

<style scoped lang="postcss">
</style>
