<template>
  <div
    class="PointshopHead
  bg-brandLight p-12
  type-tagline-xxs leading-single shadow-bottomLine
  "
  >
    <div class="flex justify-between mobOnly:flex-wrap desk:items-center desk:layout-container">
      <div class="basis-1/2 desk:basis-4/12 mobOnly:pb-8  mobOnly:border-b border-[#8ac977] desk:order-1  desk:relative top-2">
        <div>{{ $t('pointshop.head.toShopFor') }}</div>
        <div v-if="cartStore.pointsAvailable > 0" class="type-headline-sm desk:type-headline-lg desk:mt-4">
          {{ $t('pointshop.head.points', {cur: cartStore.pointsAvailable - cartStore.pointsUsed, max: cartStore.pointsAvailable}) }}
        </div>
        <div v-else>
          <LoadingIcon />
        </div>
      </div>
      <!-- countdown -->
      <div
        class="
        basis-1/2 desk:basis-2/12  desk:order-3
        mobOnly:pb-8 desk:pr-16 desk:relative top-2
        mobOnly:border-b border-[#8ac977]
        text-right"
      >
        <div>{{ $t('pointshop.head.closingIn') }}</div>
        <div
          class="fixnumbers type-headline-sm desk:type-headline-lg desk:mt-4 !normal-case"
          v-html="countdown"
        />
      </div>

      <div class="basis-full desk:basis-4/12 desk:order-2">
        <div class="text-center py-[10px] desk:pt-0">
          <span v-if="cartStore.pointStoreItemsInCart === 0">
            {{ $t('pointshop.head.noSelected') }}
          </span>
          <span v-else-if="cartStore.pointStoreItemsInCart === 1">
            {{ $t('pointshop.head.selectedSingl') }}
          </span>
          <span v-else>
            {{ $t('pointshop.head.selectedPlur', {num: cartStore.pointStoreItemsInCart}) }}
          </span>
          <span
            v-if="cartStore.pointStoreItemsInCart > 0"
            class="cursor-pointer"
            @click="clearPoints"
          >
            - <span class="underline">{{ $t('pointshop.head.undo') }}</span>
          </span>
        </div>
        <div v-if="cartStore.pointsAvailable > 0" class="flex items-center">
          <div class="type-headline-xxs desk:type-sm-medium whitespace-nowrap pr-8 shrink-0">0</div>
          <div class="border border-darkest h-4 basis-full">
            <div
              class="bg-mintPrimary h-full transition-all"
              :style="{
                width: `${usedPercent}%`
              }"
            />
          </div>
          <div class="type-headline-xxs desk:type-headline-sm whitespace-nowrap pl-8 shrink-0">
            {{ cartStore.pointsAvailable }}
          </div>
        </div>
        <div v-else>
          &nbsp;
        </div>
      </div>
      <div class="basis-full desk:basis-2/12 desk:order-4">
        <button
          class="btn w-full group mobOnly:mt-[10px]"
          :class="{
            'disabled': usedPercent === 0
          }"
          @click="showSummary"
        >
          <img
            alt=""
            src="/icons/arrow-right-inv.svg"
            class="inline-block w-16 h-16 mr-12 group-hover:translate-x-4 transition-all duration-200 ease-out"
          >
          {{ $t('pointshop.head.continue') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import useApiFetch from '~/composeables/useApiFetch';
import { CartApiResponse } from '~/constants/types/norce';
import useLinkReplacer from '~/composeables/useLinkReplacer';

const cartStore = useCartStore();
const route = useRoute();
const { apiDelete, handleApiError } = useApiFetch();
const basketId = route.params.basketId;

const usedPercent = computed(()=> {
  if (cartStore.pointsAvailable === 0 || cartStore.pointsUsed === 0) {
    return 0;
  }
  return cartStore.pointsUsed / cartStore.pointsAvailable * 100;
});

const toTime = ref<any>('');
const countdown = ref('&nbsp;');
const timer = ref<any>(null);
const { manualUrl } = useLinkReplacer();
const counter = () => {
  const countDownDate = new Date(toTime.value).getTime();
  const now = new Date().getTime();
  const distance = countDownDate - now;
  let output = '';

  if (distance >= 1) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (days > 0) {
      output += `${days.toString()} d `;
    }
    if (hours > 0 || days > 0) {
      output += `${hours.toString()} t `;
    }
    if (minutes > 0 || hours > 0 || days > 0) {
      output += `<span>${minutes.toString()}</span> m `;
    }
    // stops the annoying jumping by padding seconds and have a fixed width span
    if (minutes > 0 || hours > 0 || days > 0) {
      output += `<span>${seconds.toString().padStart(2, '0')}</span> s`;
    } else {
      output += `<span>${seconds.toString()}</span> s`;
    }

    countdown.value = output;
    // countdown.value = ` ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  }
  if (distance <= 0) {
    clearInterval(timer.value);
    countdown.value = '-';
    navigateTo(manualUrl(`/checkout/success/${basketId}`));
  }
};

onMounted(()=> {
  toTime.value = route.query.delayedUntil;
  timer.value = setInterval(counter, 1000);
});

const clearPoints = async() => {
  const res = await apiDelete<CartApiResponse>(`/basket/${basketId}/points-products`);
  if (res) {
    cartStore.alternativeCart = res;
    cartStore.pointsAvailable = res.summary.points;
    cartStore.pointsUsed = res.summary.usedPoints;
  } else {
    handleApiError();
  }
};

const showSummary = () => {
  const element = document.getElementById('CheckoutSummary');
  if (element) {
    element.scrollIntoView();
  }
};

</script>

<style  lang="postcss">
.fixnumbers {

    span {
      @apply inline-block w-16 desk:w-20;
    }

}
</style>
