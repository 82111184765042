<template>
  <div v-if="showMobileSearch" class="MobileSearch h-44" />
  <div
    class="MobileSearch bg-lightest desk:hidden"
    :class="{
      'h-44': !showMobileSearch,
      'fixed top-0 bottom-0 z-modals w-full overflow-auto modalHeight': showMobileSearch,
    }"
  >
    <div>
      <div class="relative w-full">
        <div class="relative z-30 h-44 border-b border-light ">
          <div
            v-if="showMobileSearch"
            class="text-12 absolute top-1/2 transform -translate-y-1/2 right-12 z-10"
          >
            <button
              class="h-40 px-4 pt-8"
              @click="closeSearch"
            >
              <img src="/icons/close.svg" class="h-24 w-24">
            </button>
          </div>
          <img
            src="/icons/search.svg"
            class="h-24 w-24 absolute top-[9px] left-[14px] z-10"
          >
          <input
            id="mobileSearchInput"
            v-model="search"
            class="relative w-full h-40 px-16 pr-48
              type-sm
              border border-lightest rounded-6 animate-all
              disabled:bg-lightest
              pl-44 pt-2"
            :placeholder="$t('search.placeholder')"
            type="text"
            @focus="focus"
            @click="focus"
            @keyup.enter="goToSearchPage"
          >
        </div>
      </div>
    </div>

    <div v-show="showMobileSearch">
      <SearchResult
        v-if="uiStore.searchOpenedOnceMob"
        :search="search"
        @select="(e) => search = e"
        @go-to-search-page="goToSearchPage"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import SearchResult from '~/components/search/SearchResult.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';

const uiStore = useUiStore();
const { showMobileSearch } = storeToRefs(uiStore);

const search = ref('');

const focus = () => {
  uiStore.searchOpenedOnceMob = true;
  uiStore.setShowMobileSearch(true);
};
const hide = () => {
  uiStore.setShowMobileSearch(false);
};

const closeSearch = () => {
  hide();
  search.value = '';
};

const { manualUrl } = useLinkReplacer();
const goToSearchPage = () => {
  navigateTo(manualUrl(`/search?q=${search.value}`));
  search.value = '';
};

</script>

<style scoped lang="postcss">
</style>
