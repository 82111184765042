<template>
  <div
    class="TempError flex justify-center fixed bottom-0 desk:bottom-24 z-globalError w-full cursor-pointer p-12"
    @click="uiStore.tempErrorMsg = ''"
  >
    <div class="px-12 py-12 bg-criticalDark text-lightest type-sm flex items-start gap-6 mobOnly:w-full">
      <div class="p-2 shrink-0">
        <img
          src="/icons/warning-inv.svg"
          class="w-20 h-20"
          alt=""
        >
      </div>
      <div class="shrink grow mt-1"> {{ uiStore.tempErrorMsg }} </div>
      <div class="p-2 shrink-0 rounded-6 hover:bg-transparentBlack transition-all">
        <img
          src="/icons/close-inv.svg"
          class="w-20 h-20"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();

</script>

<style scoped lang="postcss">

</style>
