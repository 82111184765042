<template>
  <div class="CartIcon w-24 h-24">
    <button
      class="relative cursor-pointer"
      @click="openCart"
      @mouseenter="sendDebounce"
    >
      <div
        class="relative"
        :class="{
          favoriteAnimateIn: showAnimation
        }"
      >
        <img
          src="/icons/cart.svg"
          class="w-24 h-24"
          :alt="$t('header.cart')"
        >
        <div
          v-if="cartStore.isLoading"
          class="absolute top-8 left-6"
        >
          <img
            src="/icons/spinner.svg"
            class="h-12 w-12 spin"
            :alt="$t('loading')"
          >
        </div>
        <div
          v-else-if="cartItems > 0"
          class="flex items-center justify-center w-20 h-20
          absolute -top-6 -right-[9px]
          bg-brandPrimary type-headline-xs rounded-full"
        >
          {{ cartItems }}
        </div>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { storeToRefs } from 'pinia';
const uiStore = useUiStore();

const cartStore = useCartStore();

const { cartItems } = storeToRefs(cartStore);

const openCart = () => {
  cartStore.sendDebouncedQueue();
  uiStore.setShowMiniCart(true);
};

const sendDebounce = () => {
  cartStore.sendDebouncedQueue();
};

const showAnimation = ref(false);
watch(cartItems, (newVal, oldVal)=> {
  if (newVal > oldVal) {
    showAnimation.value = true;
    setTimeout(()=> showAnimation.value = false, 2000);
  }
});

</script>

<style scoped lang="postcss">
</style>
